<template>
  <div id="app">
    <div class="SiteHero">
      <video
        v-if="showVideo"
        class="SiteHero-video"
        autoplay
        loop
        muted
      >
        <source src="@/assets/web3.mp4" type="video/mp4">
      </video>
      <img class="SiteHero-logo" src="@/assets/vizio-logo.svg" alt="Vizio">
      <div class="SiteHero-content">
        <h1 class="SiteHero-title">Vizio</h1>
        Staviame funkčné webové aplikácie na&nbsp;mieru
      </div>
    </div>

    <div class="Section SectionClients">
      <h2>Naši klienti</h2>
      <div class="SectionClients-list Container">
        <div
          v-for="(client, i) in clients"
          :key="i"
          class="ClientLogo"
        >
          <img
            class="ClientLogo-image"
            :src="client.logo"
            :alt="client.name"
            :title="client.name"
          >
        </div>
      </div>
    </div>

    <div class="Section SectionProjects">
      <h2>Posledné projekty</h2>
      <div class="Container">
        <div class="Row">
          <div
            v-for="(project, i) in projects"
            :key="i"
            class="Column Column-6 Project"
          >
            <div class="Project-window">
              <img src="@/assets/window-header.svg" class="Project-window-header" alt="">
              <img
                class="Project-preview"
                :src="project.preview"
                :alt="project.title"
              >
            </div>
            <h3 class="Project-text">
              <span class="Project-type">{{ project.type }}</span>
              <span class="Project-title">{{ project.title }}</span>
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="SiteFooter">
      <div class="Container">
        <div class="Row">
          <div class="Column Column-4">
            <ul>
              <li>
                <a href="mailto:vizio@vizio.sk">vizio@vizio.sk</a>
              </li>
              <li>
                <a href="tel:+421907353828">+421 907 353 828</a>
              </li>
            </ul>
          </div>
          <div class="Column Column-4">
            <address>
              Vizio s.r.o.<br>
              Staré Grunty 18,<br>
              841 04 Bratislava<br>
            </address>
            <ul>
              <li>IČO 35841338</li>
              <li>IČDPH SK2020288028</li>
            </ul>
          </div>
          <div class="Column Column-4">
            <a class="ContactButton" href="mailto:vizio@vizio.sk">
              <span class="ContactButton-text">Kontaktujte nás</span>
              <span class="ContactButton-arrow"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'normalize.css';

export default {
  name: 'App',
  components: {
  },
  mounted() {
    this.showVideo = true;
  },
  data: () => ({
    showVideo: false,
    clients: [
      {
        name: 'Generali poisťovňa, a.s.',
        logo: require('@/assets/clients/generali.svg'),
      },
      {
        name: 'Wealth Effect Management',
        logo: require('@/assets/clients/wem.svg'),
      },
      {
        name: 'Inca Collagen',
        logo: require('@/assets/clients/inca-collagen.svg'),
      },
      {
        name: 'We Make Games',
        logo: require('@/assets/clients/wmg.png'),
      },
      {
        name: 'Bezobalovo',
        logo: require('@/assets/clients/bezobalovo.svg'),
      },
      {
        name: 'Begam',
        logo: require('@/assets/clients/begam.svg'),
      },
      {
        name: 'Centrum environmentálnej a etickej výchovy Živica',
        logo: require('@/assets/clients/zivica.svg'),
      },
      {
        name: 'Novartis',
        logo: require('@/assets/clients/novartis.svg'),
      },
    ],
    projects: [
      {
        type: 'Ecommerce',
        title: 'Bezobalovo.sk',
        preview: require('@/assets/projects/bezobalovo.jpg'),
      },
      {
        type: 'Web',
        title: 'Generali.sk',
        preview: require('@/assets/projects/generali-web.jpg'),
      },
      {
        type: 'Webová aplikácia',
        title: 'Životné poistenia Generali',
        preview: require('@/assets/projects/generali-kalkulacka-zp.png'),
      },
      {
        type: 'Web',
        title: 'Cruiser Aircraft',
        preview: require('@/assets/projects/cruiser-aircraft.jpg'),
      },
    ],
  }),
};
</script>

<style lang="scss">
$white: #FFFFFF;
$gray-800: #191919;
$gray-900: #000;
$green: #5BE8C7;
$purple: #A960D9;

$screen-md: 768px;

@font-face {
  font-family: 'Geomanist';
  src: url('./assets/fonts/geomanist-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/geomanist-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('./assets/fonts/geomanist-bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/geomanist-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@mixin hexagon() {
  $left: 13%;
  $right: 100% - $left;
  clip-path: polygon($left 0%, $right 0%, 100% 50%, $right 100%, $left 100%, 0% 50%);
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
  background-color: $gray-800;
  color: $white;
  font-size: 16px;

  font-family: Geomanist, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 1rem;
}

.Row {
  display: flex;
  flex-wrap: wrap;
}

.Column {
  padding: 0 1rem;
  flex: 0 0 100%;
}
@media screen and (min-width: $screen-md) {
  @for $i from 1 through 12 {
    .Column-#{$i} {
      $width: 100% / 12 * $i;
      flex: 0 0 $width;
      max-width: $width;
    }
  }
}

.SiteHero {
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background-color: $gray-900;
  box-shadow: 0 0.5rem 3rem $gray-900;
  overflow: hidden;
  &-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate3d(-50%, -50%, 0);
  }
  &-logo {
    position: relative;
    display: block;
    width: 7rem;
    margin-bottom: 2rem;
  }
  &-content {
    font-size: 2rem;
    position: relative;
  }
  &-title {
    display: none;
  }
  @media screen and (min-width: $screen-md) {
    margin: 2rem;
    height: max(30rem, 40vw);
    padding: 4rem;
    justify-content: center;
    border-radius: 0.75rem;
    &-logo {
      position: absolute;
      top: 4rem;
      width: 10rem;
    }
    &-content {
      font-size: 3.25rem;
      max-width: 9em;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0; right: 0; top: 0; bottom: 0;
      border: 1px solid rgba(white, 0.15);
      transition: border .4s;
      border-radius: 0.75rem;
    }
    &:hover::after {
      border: 0.5rem solid $purple;
    }
  }
}

.Section {
  padding: 2rem 0;
  h2 {
    text-align: center;
    margin: 0 0 2rem;
    font-size: 1.25rem;
  }
}

.SectionClients {
  &-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.ClientLogo {
  flex: 0 0 50%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  transition: opacity .4s;
  &-image {
    max-width: 75%;
    max-height: 60%;
    width: auto;
    height: auto;
  }
  &:hover {
    opacity: 1;
  }
  @media screen and (min-width: $screen-md) {
    flex: 0 0 25%;
    height: 9rem;
  }
}

.Project {
  margin-bottom: 2rem;
  &-window {
    transition: transform .3s;
    &:hover {
      transform: scale(1.03);
    }
  }
  &-window-header {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }
  &-preview {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 0 0 0.5rem 0.5rem;
  }
  &-text {
    font-size: 1rem;
  }
  &-type {
    opacity: 0.5;
    margin-right: 0.5rem;
  }
}

.SiteFooter {
  background-color: $gray-900;
  padding: 4rem 0;
  font-weight: 700;
  address,
  ul {
    margin: 0 0 1rem;
  }
  address {
    font-style: normal;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover {
    color: $green;
  }
}

.ContactButton {
  display: flex;
  align-items: center;
  width: 12rem;
  &-text {
    margin-right: 0.5rem;
  }
  &-arrow {
    flex: 1;
    height: 18px;
    background-image: url('./assets/arrow.svg');
    background-position: right;
    background-size: cover;
  }
  @media screen and (min-width: $screen-md) {
    width: 100%;
  }
}
</style>
